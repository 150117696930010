<template>
    <wrapper>
        <div v-if="debugbtn">
            <span>頁面編號調試：</span>
            <input v-model="page" />
        </div>
        <div
            v-if="page == 1"
            class="box"
        >
            <div class="title">健康飲食的障礙</div>

            <div v-show="!vg_complete">
            <p>
                通常我哋喺進行健康飲食嘅時候會面臨好多困難，令到我哋唔可以堅持落去，而家等我哋黎學下點樣克服健康飲食可能會遇到嘅障礙。
            </p>
            <p>
                喺好多情況下，你會覺得食到<span class = "b">足夠份量嘅生果蔬菜</span>（即喺每日至少五份生果蔬菜）<span class = "b">好困難</span>。針對呢啲問題，你會點樣應對？請認真思考，然後回答以下問題。
            </p>
            <p>
                1. 就算我好忙，我都會盡量食多啲生果蔬菜。
            </p>
            <p>不符合<cube-rate v-model="barrier.vg_rate1"></cube-rate>符合我</p><br />
            <p>
                2. 就算牙唔好，咬野有困難，我都會盡量食多啲生果蔬菜。
            </p>
            <p>不符合<cube-rate v-model="barrier.vg_rate2"></cube-rate>符合我</p><br />
            <p>
                3. 就算出去食飯或者度假，我都會盡量食多啲生果蔬菜。
            </p>
            <p>不符合<cube-rate v-model="barrier.vg_rate3"></cube-rate>符合我</p><br />
            <p>
                4. 就算菜唔岩口味，我都會盡量食多啲。
            </p>
            <p>不符合<cube-rate v-model="barrier.vg_rate4"></cube-rate>符合我</p><br />
            <p>
                5.就算生果蔬菜好貴，我都會盡量食多啲。
            </p>
            <p>不符合<cube-rate v-model="barrier.vg_rate5"></cube-rate>符合我</p><br />
            </div>


           <div v-if="vg_complete">
                <p>你啱先已經指出，就算喺呢啲情況下，你都可以食足夠嘅生果蔬菜 ：</p>
                <p class="limegreen" v-if="barrier.vg_rate1 > 3">好忙</p>
                <p class="limegreen" v-if="barrier.vg_rate2 > 3">牙唔好，咬野有困難</p>
                <p class="limegreen" v-if="barrier.vg_rate3 > 3">出去食飯或者度假</p>
                <p class="limegreen" v-if="barrier.vg_rate4 > 3">菜唔岩口味</p>
                <p class="limegreen" v-if="barrier.vg_rate5 > 3">生果蔬菜比較貴</p>
                <br />
                <p>但係喺呢啲情況下，你覺得食足夠嘅生果蔬菜會有困難 ：</p>
                <p class="red" v-if="barrier.vg_rate1 < 4">好忙</p>
                <p class="red" v-if="barrier.vg_rate2 < 4">牙唔好，咬野有困難</p>
                <p class="red" v-if="barrier.vg_rate3 < 4">出去食飯或者度假</p>
                <p class="red" v-if="barrier.vg_rate4 < 4">菜唔岩口味</p>
                <p class="red" v-if="barrier.vg_rate5 < 4">生果蔬菜比較貴</p>

            </div>
        </div>
        <div
            v-if="page == 2"
            class="box"
        >
            <div class="title">進食足夠份量生果蔬菜嘅障礙</div>
            <p class="blue">
                唔好咁灰。面對困難，諗個應對計劃就可以幫到你。其他人嘅例子可能都會對你有啟發。
            </p>
            <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_5_Barriers_vg.mp3" ></audio>
            <p>
                胡先生（60歲）：我以前很少進食蔬菜，只是偶爾在午飯時吃一點蔬菜。吃生果也大多只吃蛋糕上的那一點生果，平常也想不起來什麼時候吃生果。雖然我知道多吃生果蔬菜，對身體健康有益處。但是我總是半途而廢，很難堅持下來。後來我身邊的一個朋友分享了他堅持吃生果蔬菜的秘訣，就是制定對應計劃。例如，思考堅持吃生果蔬菜可能會遇到的困難以及制定克服這些困難的計劃。</p><p>例如，對家裡做的蔬菜吃膩了，於是在網絡上看看別人做蔬菜的影片，學習不同的做法。通過制定對應計劃，我已經慢慢開始有規律咁吃生果蔬菜了。例如每天吃一個香蕉和蘋果。在午餐和晚餐的時候，也都會吃蔬菜。我還會繼續制定飲食計劃，堅持多吃生果蔬菜，最終養成健康飲食的好習慣。
            </p>
            <img  class="fw8" src="../../assets/img/di_barrier_story1.jpg" />
        </div>
        <div
            v-if="page == 3"
            class="box"
        >
            <div class="title">進食足夠份量生果蔬菜嘅障礙</div>
            <p>
                如果你有食多啲生果蔬菜嘅諗法，但一直都冇去做。<span class="b">諗下有啲咩原因阻礙你食生果蔬菜呢</span>？
            </p>
            <p>請用幾分鐘時間諗下，揀出或者寫低嘅障礙同困難。</p>
            <p>我最難應對嘅障礙喺</p>
            <p class="select_line">
                <cube-select
                    class="inline_select"
                    v-model="barrier.data[0].type"
                    :options="barrierList(0)"
                >
                </cube-select>
                <span v-if="barrier.data[0].type == otherTxt">請輸入<cube-input
                        class="other_input"
                        v-model="barrier.data[0].type_other"
                    >
                    </cube-input></span>。
            </p>
        </div>
        <div
            v-if="page == 4"
            class="box"
        >
            <div class="title">健康飲食障礙對應計劃</div>
            <p>
                訂下有效的對應計劃最方便嘅辦法就係<span class="bu">造句法</span>，用<span>“如果…….咁…….”</span>造句。</p>
            <p>對應計劃越貼近你嘅實際情況，越有效果。跟住落黎，請你寫低你嘅對應計劃</p>

            <p class="select_line">
                1． 如果{{ type1 }}，咁<cube-input
                    class="other_input"
                    v-model="barrier.data[0].then"
                >
                </cube-input>
            </p>

            <p class="b blue">如果你唔知點寫，請參考我哋俾嘅例子。</p>

            <ul>
                <li>
                    <p v-if="showeg_vg(0)" class="borange" >
                        如果我好忙，咁我會提前喺屋企或者公司放d生果，方便每日食。
                    </p>
                </li>
                <li>
                    <p v-if="showeg_vg(1)" class="borange" >
                        如果牙唔好，咬野有困難，咁我會盡量揀d軟熟嘅生果，比如香蕉、奇異果、提子。蔬菜我都會揀軟身同煮腍嘅，比如冬瓜，蘿蔔同菠菜。
                    </p>
                </li>
                <li>
                    <p v-if="showeg_vg(2)" class="borange" >
                        如果出去食飯或者度假，
                        咁我就會刻意的要求自己喺出面食飯嘅時候，都要食足夠嘅蔬菜同生果。
                    </p>
                </li>
                <li>
                    <p v-if="showeg_vg(3)" class="borange" >
                       如果蔬菜唔岩口味，咁我都會試下其他嘅菜，或者試下唔同嘅煮法，比如做蔬菜餅。 
                    </p>
                </li>
                <li>
                    <p v-if="showeg_vg(4)" class="borange" >
                       如果生果蔬菜比較貴，咁我會揀街市就黎閂門嘅時候去買，咁樣就比較平。 
                    </p>

                </li>
            </ul>
            <p class="b">
               你嘅障礙對應計劃將會儲存喺電話主頁“<span class="b red">飲食健康</span>”
                嘅“對應計劃”一欄中，你可以隨時查閲
            </p>
        </div>

        <div
            v-if="page == 5"
            class="box"
        >
            <div class="title">肉、蛋及替代品</div>
            <div v-show="!meat_complete">
                            <p>
                喺好多情況下，你會覺得<span class="b">食適量嘅肉、蛋同替代品</span>（每日食5-6兩）<span class="b">好困難</span>。針對呢啲情況，你會點樣應對？請認真思考，然後回答以下問題。
            </p>
            <p>
                1. 就算我好忙，我每日都會食適量嘅肉、蛋同替代品。
            </p>
            <p>不符合<cube-rate v-model="barrier.meat_rate1"></cube-rate>符合我</p><br />
            <p>
                2. 就算牙唔好，咬野有困難，我每日都會食適量嘅肉、蛋同替代品。
            </p>
            <p>不符合<cube-rate v-model="barrier.meat_rate2"></cube-rate>符合我</p><br />
            <p>
                3. 就算出去食飯或者度假，我都會控制自己食適量嘅肉、蛋同替代品。
            </p>
            <p>不符合<cube-rate v-model="barrier.meat_rate3"></cube-rate>符合我</p><br />
            <p>
                4. 就算我唔鐘意食某d肉類或魚類，我都會揀食豆製品或者蛋類補充植物性蛋白質。
            </p>
            <p>不符合<cube-rate v-model="barrier.meat_rate4"></cube-rate>符合我</p><br />
            <p>
                5. 就算有啲魚或肉類比較貴，我都會盡量保證每日都食適量嘅肉、蛋同替代品。
            </p>
            <p>不符合<cube-rate v-model="barrier.meat_rate5"></cube-rate>符合我</p><br />
            </div>


            <div v-if="meat_complete">
                <p>你啱先已經指出，喺呢啲情況下，你仍然可以食適量嘅肉、蛋同替代品 ：</p>
                <p class="limegreen" v-if="barrier.meat_rate1 > 3">好忙</p>
                <p class="limegreen" v-if="barrier.meat_rate2 > 3">牙唔好，咬野有困難</p>
                <p class="limegreen" v-if="barrier.meat_rate3 > 3">出去食飯或者度假</p>
                <p class="limegreen" v-if="barrier.meat_rate4 > 3">唔鐘意食某d肉類或魚類</p>
                <p class="limegreen" v-if="barrier.meat_rate5 > 3">有啲魚或肉類比較貴</p>
                <br />
                <p>但係喺呢啲情況下，你食適量嘅肉、蛋同替代品喺有困難 ：</p>
                <p class="red" v-if="barrier.meat_rate1 < 4">好忙</p>
                <p class="red" v-if="barrier.meat_rate2 < 4">牙唔好，咬野有困難</p>
                <p class="red" v-if="barrier.meat_rate3 < 4">出去食飯或者度假</p>
                <p class="red" v-if="barrier.meat_rate4 < 4">肉或者魚類唔岩胃口</p>
                <p class="red" v-if="barrier.meat_rate5 < 4">肉或者魚類比較貴</p>
            </div>
        </div>

        <div
            v-if="page == 6"
            class="box"
        >
            <div class="title">障礙------肉、蛋同替代品</div>
            <p  class="blue">
                唔好咁灰。針對困難情況，諗出對應計劃會對你有幫助。人地嘅例子都可能會對你有所啟發。
            </p>
            <audio  class="gaudio" controls controlsList="nodownload"  preload="auto" src="@/assets/guide/diet/Week_5_Barriers_meat.mp3" ></audio>
            <p>
                劉先生（66歲）：我以前每天早上都要吃兩隻雞蛋還有午餐肉，每天下午茶時間都會吃一種肉排（豬排、雞排或牛排），午餐和晚餐沒有肉我都吃不下飯。後來去醫院做體檢，發現自己的膽固醇偏高，血壓也不正常，我就開始意識到自己要控制每天的肉類攝取量。後來我和老伴説，去街市買餸時買少一些肉。但是想要改變以前不良的飲食習慣太難了。</p><p>為了控制好每天的肉類攝取量，我開始嘗試逐步減量法。例如，早餐只吃一隻雞蛋，下午茶盡量不吃肉類，改為吃生果和點心，午餐和晚餐吃的肉也比之前少一半。通過循序漸進的方法，我慢慢覺得自己可以做到堅持吃適量的肉類，並逐漸養成了健康的飲食習慣。
            </p>
            <img  class="fw8" src="../../assets/img/di_barrier_story1.jpg" />
        </div>

        <div
            v-if="page == 7"
            class="box"
        >
            <div class="title">障礙——肉、蛋同替代品</div>
            <p>
                如果你有食適量嘅肉、蛋同替代品的想法，但一直都冇去做。<span class="b">諗下有啲咩原因阻礙你咁樣做呢</span>？
            </p>
            <p>請用幾分鐘嘅時間思考下，揀出或者寫低你遇到嘅障礙同困難。</p>
            <p>我最難應對嘅障礙喺</p>
            <p class="select_line">
                <cube-select
                    class="inline_select"
                    v-model="barrier.data[1].type"
                    :options="barrierList(1)"
                >
                </cube-select>
                <span v-if="barrier.data[1].type == otherTxt">請輸入<cube-input
                        class="other_input"
                        v-model="barrier.data[1].type_other"
                    >
                    </cube-input></span>。
            </p>
        </div>

        <div
            v-if="page == 8"
            class="box"
        >
           <div class="title">健康飲食障礙對應計劃</div>
            <p>
                訂下有效的對應計劃最方便嘅辦法就係<span class="bu">造句法</span>，用<span>“如果…….咁…….”</span>造句。</p>
            <p>對應計劃越貼近你嘅實際情況，越有效果。跟住落黎，請你寫低你嘅對應計劃</p>
            <p class="select_line">
                1． 如果{{ type2 }}，咁<cube-input
                    class="other_input"
                    v-model="barrier.data[1].then"
                >
                </cube-input>
            </p>

            <p class="b">如果你唔知點寫，請參考我哋俾嘅例子。</p>

            <ul>
                <li>
                    <p v-if="showeg_meat(0)"  class="borange" >
                        如果我好忙，咁我會提前喺屋企或者公司放d堅果，方便每日食。
                    </p>
                </li>
                <li>
                    <p v-if="showeg_meat(1)"  class="borange" >
                        如果牙唔好，咬野有困難，咁我會盡量揀d軟質的食物，比如炒蛋、軟嫩肉類、三文魚沙拉、 絞肉丸、豆腐等。
                    </p>
                </li>
                <li>
                    <p v-if="showeg_meat(2)"  class="borange" >
                        如果出去食飯或者度假，
                        咁我就會刻意的要求自己喺出面食飯嘅時候，食適量嘅魚肉，唔可以太多。
                    </p>
                </li>
                <li>
                    <p v-if="showeg_meat(3)"  class="borange" >
                        如果我唔鐘意食某d肉類或魚類，咁我會揀食豆製品、蛋類補充植物性蛋白質 。
                    </p>
                </li>
                <li>
                    <p v-if="showeg_meat(4)"  class="borange" >
                        如果我鐘意食嘅某種魚或肉類比較貴，咁我會揀街市就黎閂門嘅時候去買，咁樣就會平啲。
                    </p>
                </li>
            </ul>
            <p class="b">
                你嘅障礙對應計劃將會儲存喺電話主頁“<span class="b red">飲食健康</span>”
                嘅“對應計劃”一欄中，你可以隨時查閲
            </p>
        </div>

        <div
            v-if="page == 9"
            class="box"
        >
            <div class="title">健康飲食障礙對應計劃總結</div>
            <p class="b">當你喺健康飲食過程中遇到困難，請用下低嘅對應計劃幫助自己克服困難！</p>
            <p><span class="blue">如果</span>{{ type1 }}，<span class="blue">咁</span>{{ barrier.data[0].then }}</p>
            <p><span class="blue">如果</span>{{ type2 }}，<span class="blue">咁</span>{{ barrier.data[1].then }}</p>
            <div v-if="num > 5  && false">
                <br />
                <p class="b">下低喺用兩大方法對你嘅健康飲食障礙對應計劃嘅評估</p>
                <p>
                    1. <span class="b">適合性</span> 呢啲食物喺咪適合你？({{
            barrier.perEval.evl2
          }})
                </p>
                <p>
                    2. <span class="b">有效性</span> 食呢啲食物對你嘅健康有冇好處？({{
            barrier.perEval.evl3
          }})
                </p>
            </div>
            <div v-if="!reviewMode">
                <p>你可以揀喺咪要進行調整。</p>
                <cube-radio-group v-model="modify">
                    <cube-radio
                        key="1"
                        :option="{ value: 1 }"
                    > 調整</cube-radio>
                    <cube-radio
                        key="0"
                        :option="{ value: 0 }"
                    > 唔調整</cube-radio>
                </cube-radio-group>
            </div>
        </div>

        <div
            v-if="page == 10"
            class="box"
        >
            <div class="title">評估健康飲食障礙對應計劃</div>
            <p>
                上個禮拜你已經制定咗健康飲食障礙對應計劃，幫助你克服困難，堅持健康飲食。跟住落黎，請你評估一下對應計劃嘅實行情況。如果呢啲對應計劃對你幫助唔大，咁就進行調整。
            </p>

            <p>
                而家請回憶你上個禮拜制定嘅健康飲食障礙對應計劃（<span
                    class="bu"
                    @click="reviewPlan(10)"
                >點擊查看</span>）同埋你完成呢個計劃嘅情況。請指出，喺最近7日實行呢個健康飲食障礙對應計劃嘅情況。
            </p>
            <div v-if="barrier.perEval.evl1 > 0">
                <p
                    v-if="barrier.perEval.evl1 == 1"
                    class="red b"
                >
                    改變習慣唔易架，唔好氣餒，繼續加油！
                </p>
                <p
                    v-if="barrier.perEval.evl1 == 2"
                    class="red b"
                >
                    仲有進步的空間，繼續加油!
                </p>
                <p
                    v-if="barrier.perEval.evl1 == 3"
                    class="red b"
                >
                    做的非常好，請繼續保持！
                </p>
            </div>
            <cube-radio-group v-model="barrier.perEval.evl1">
                <cube-radio
                    key="0"
                    :option="{ value: 1 }"
                >
                    <p>不好</p>
                </cube-radio>
                <cube-radio
                    key="1"
                    :option="{ value: 2 }"
                >
                    <p>一般</p>
                </cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: 3 }"
                >
                    <p>好</p>
                </cube-radio>
            </cube-radio-group>

        </div>

        <div
            v-if="page == 11"
            class="box"
        >
            <div class="title">評估健康飲食障礙對應計劃</div>
            <p>而家請你運用兩大方法黎評估你嘅健康飲食障礙對應計劃。</p>
            <p>1. 適合性 呢啲食物係咪適合你？</p>
            <cube-radio-group v-model="barrier.perEval.evl2">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
            <p>2. 有效性 食呢啲食物對你嘅健康有冇好處？</p>
            <cube-radio-group v-model="barrier.perEval.evl3">
                <cube-radio
                    key="1"
                    :option="{ value: '是' }"
                > 是</cube-radio>
                <cube-radio
                    key="2"
                    :option="{ value: '否' }"
                > 否</cube-radio>
            </cube-radio-group>
        </div>
    </wrapper>
</template>


<script>
import tools from "@/data/tools";
import wrapper from "@/components/wrapper";
import { allVgBarrier, allMeatBarrier, validErr } from "@/data/data";

export default {
    name: "pa_barrier",
    data() {
        return {
            page: 0,
            otherTxt: "其他障礙",
            modify: 0,
            reviewMode: true,
            reviewFrom: 0,
            barrier: {
                // 蔬菜的5項評分
                vg_rate1: 0,
                vg_rate2: 0,
                vg_rate3: 0,
                vg_rate4: 0,
                vg_rate5: 0,

                meat_rate1: 0,
                meat_rate2: 0,
                meat_rate3: 0,
                meat_rate4: 0,
                meat_rate5: 0,

                data: [
                    {
                        type: "",
                        type_other: "",
                        then: "",
                    },
                    {
                        type: "",
                        type_other: "",
                        then: "",
                    }
                ],
                perEval: {
                    evl1: 0,
                    evl2: "",
                    evl3: "",
                },
            },
            perweek: {},
        };
    },
    computed: {
        type: function () {
            return this.$store.state.type;
        },
        num: function () {
            return this.$store.state.week;
        },
        user: function () {
            return this.$store.state.user;
        },
        diet: function () {
            return this.$store.state.diet;
        },
        debugbtn: function () {
            return this.$store.state.debugbtn;
        },

        the_num: function () {
            return tools.the_num(this.num);
        },
        vg_complete: function () {
            for (let i = 1; i <= 5; i++) {
                if (this.barrier["vg_rate" + i] == 0) return false;
            }
            return true;
        },
        meat_complete: function () {
            for (let i = 1; i <= 5; i++) {
                if (this.barrier["meat_rate" + i] == 0) return false;
            }
            return true;
        },
        type1: function () {
            return this.barrier.data[0].type == this.otherTxt
                ? this.barrier.data[0].type_other
                : this.barrier.data[0].type;
        },
        type2: function () {
            return this.barrier.data[1].type == this.otherTxt
                ? this.barrier.data[1].type_other
                : this.barrier.data[1].type;
        }
    },
    created() {
        if (!this.page) {
            if (this.num == 5 || this.num == 1) this.page = 1;
            else this.page = 10;
        }

        if (this.diet[this.num] != null) {
            this.barrier = tools.copy(this.diet[this.num].barrier);
        } else {
            //編輯，先查看是否有緩存
            if (this.diet[0].barrier) {
                this.barrier = this.diet[0].barrier;
            }
        }
        // 無論查看還是編輯， 初始化上週內容
        if (this.diet[this.num - 1] != null) {
            this.perweek = tools.copy(this.diet[this.num - 1].barrier);
        }
        
        if (!this.$store.state.review && this.num > 5) {
            this.barrier = tools.copy(this.perweek);
            this.barrier.perEval.evl1 = 0;
        }
    },
    methods: {
        reviewPlan: function (num) {
            this.reviewFrom = num;
            this.page = 9;
        },
        showeg_vg: function (order) {
            if (
                this.barrier.data[0].type == this.otherTxt
            )
                return true;
            if (
                this.barrierOrder_vg(this.barrier.data[0].type) == order
            )
                return true;
            return false;
        },
        showeg_meat: function (order) {
            if (
                this.barrier.data[1].type == this.otherTxt
            )
                return true;
            if (
                this.barrierOrder_meat(this.barrier.data[1].type) == order
            )
                return true;
            return false;
        },
        barrierOrder_vg: function (val) {
            for (let i = 0; i < allVgBarrier.length; i++) {
                if (val == allVgBarrier[i]) return i;
            }
            return 5;
        },
        barrierOrder_meat: function (val) {
            for (let i = 0; i < allMeatBarrier.length; i++) {
                if (val == allMeatBarrier[i]) return i;
            }
            return 5;
        },
        barrierList: function (order) {
            let list = [];
            if (order === 0)
                for (let item of allVgBarrier) {
                        list.push(item);
                }
            else if (order === 1) {
                for (let item of allMeatBarrier) {
                        list.push(item);
                }
            }
            return list;
        },
        save: function () {
                        if(this.$store.state.review)
                return;
            let xml = {};
            xml.key = [
                "5種果蔬障礙打分",
                "5種肉類障礙打分",
                "果蔬應對1",
                "肉類應對1",
                "上週實行情況",
                "適合性評價",
                "有效性評價",
            ];
            xml.data = [];
            xml.data.push(
                [
                    this.barrier.vg_rate1,
                    this.barrier.vg_rate2,
                    this.barrier.vg_rate3,
                    this.barrier.vg_rate4,
                    this.barrier.vg_rate5,
                ].join(";")
            );
            xml.data.push(
                [
                    this.barrier.meat_rate1,
                    this.barrier.meat_rate2,
                    this.barrier.meat_rate3,
                    this.barrier.meat_rate4,
                    this.barrier.meat_rate5,
                ].join(";")
            );
            xml.data.push(
                "如果" + this.type1 + "那麼" + this.barrier.data[0].then
            );
            xml.data.push(
                "如果" + this.type2 + "那麼" + this.barrier.data[1].then
            );

            xml.data.push(
                ["未填寫", "不好", "一般", "好"][this.barrier.perEval.evl1]
            );
            xml.data.push(this.barrier.perEval.evl2);
            xml.data.push(this.barrier.perEval.evl3);
            this.barrier.xml = xml;

            this.diet[0].barrier = tools.copy(this.barrier);
        },
        pause: function () {
            this.save();
        },
        leave: function (page) {
            if (typeof this.page == "string") this.page = parseInt(this.page);

            if (page == 11) {
                this.reviewMode = false;
            }
            if (page == 3) {
                this.reviewMode = true;
            }
            if (page == 8) {
                this.modify = 0;
            }
        },
        valid: function (page) {
            if (page == 1) {
                if (!this.vg_complete) {
                    return validErr.empty;
                }
            } else if (page == 5) {
                if (!this.meat_complete) {
                    return validErr.empty;
                }
            } else if (page == 3) {
                if (this.barrier.data[0].type.length == 0 || (
                    this.barrier.data[0].type == this.otherTxt &&
                    !this.barrier.data[0].type_other
                ))
                    return validErr.empty;
            } else if (page == 4) {
                if (!this.barrier.data[0].then) return validErr.empty;
            } else if (page == 7) {
                if (this.barrier.data[1].type.length == 0 || (
                    this.barrier.data[1].type == this.otherTxt &&
                    !this.barrier.data[1].type_other
                ))
                    return validErr.empty;
            } else if (page == 8) {
                if (!this.barrier.data[1].then) return validErr.empty;
            }
                        else if(page == 10)
            {
                if(this.barrier.perEval.evl1 == 0)
                    return validErr.empty;
            }
            else if(page == 11)
            {
                if(!this.barrier.perEval.evl2 || !this.barrier.perEval.evl3)
                    return validErr.empty;
            }
        },
        next: function () {
            console.log("barrier do next");
            if (this.valid(this.page)) {
                this.$createDialog({
                    type: "alert",
                    title: "",
                    content: this.valid(this.page),
                }).show();
                return;
            }
            this.leave(this.page);

            let done = false;
            switch (this.page) {
                // 生果打星
                case 1:
                    this.page = 2;
                    break;
                // 生果鼓勵
                case 2:
                    this.page = 3;
                    break;
                // 選出生果障礙
                case 3:
                    this.page = 4;
                    break;
                // 生果的如果，那麼
                case 4:
                    if (this.modify == 1) this.page = 7;
                    else this.page = 5;
                    break;
                // 肉類打星
                case 5:
                    this.page = 6;
                    break;
                // 肉類鼓勵
                case 6:
                    this.page = 7;
                    break;
                // 選出肉類障礙
                case 7:
                    this.page = 8;
                    break;
                // 肉類的如果，那麼
                case 8:
                    this.page = 9;
                    break;
                // 綜合總結
                case 9:
                    if (this.reviewFrom !== 0) {
                        this.page = this.reviewFrom;
                        this.reviewFrom = 0;
                    } else if (this.modify == 1) this.page = 3;
                    else done = true;
                    break;
                // 回顧上週，評價
                case 10:
                    this.page = 11;
                    break;
                // 回顧上週，2大方法評價
                case 11:
                    this.page = 9;
                    break;
            }
            if (done) {
                this.save();
                return this.page;
            }
        },
    },

    components: {
        wrapper: wrapper,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="../../assets/css/learn_common.css" />
<style scoped>

.other_input{
    width:80%;
}

</style>